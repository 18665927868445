import React from "react";

class Product extends React.Component {
    constructor(props) {
      super(props);
      this.onClick = this.onClick.bind(this);
    };

    onClick = (event)=>{
        event.preventDefault() //阻止表单提交
        this.props.onClick(this.props.goodsId,this.props.productName);
    }
    
    render() {
        let {productName} = this.props;
        let {onClick} = this;
        return (
          <p> 
            <button type="button" className="btn btn-primary btn-lg btn-block"  onClick={onClick} >{productName}</button>  
          </p>
        );
    }
  }
  
  export default Product;