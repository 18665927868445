import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , NavLink, Card ,Container,Modal,Spinner ,Breadcrumb,Row,Col } from 'react-bootstrap';
import React from 'react';
import QRCode  from 'qrcode-react'


class FlexProductPay extends React.Component {
  constructor(props) {
    super(props);

    console.log(window);
    if(window.location.hostname!=="localhost"){
      //hao.zhongdaiqi.com ->  hmb.zhongdaiqi.com
      if(window.location.hostname==="hao.zhongdaiqi.com"){
          //确保是https
          if(window.location.protocol==="http:"){
            console.log("自动跳转到https 确保用户数据安全");  
            window.location.href=window.location.href.replace("http://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }else{
            window.location.href=window.location.href.replace("https://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }
      }

      //确保是https
      if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
      }
    }

    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    this.state = {
      product:{

      },
      u:u,
      showBtn:true,
      orderCode:props.match.params.ordercode,
      token:props.match.params.token
    };

   

 
     

    

}






 



  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
            <Breadcrumb.Item href={"#/cha?u="+this.state.u}>
               查单
            </Breadcrumb.Item>

            <Breadcrumb.Item active>免费领取</Breadcrumb.Item>
          </Breadcrumb>

 
             <div style={{margin:'20px'}}>下单成功，等待支付</div>
             <div style={{margin:'20px'}}>付款方式一 直接付款</div>
             <NavLink href={"https://hmb.zhongdaiqi.com/hopeapi/order/pay?orderCode="+this.state.orderCode+"&token="+this.state.token}>
                <button  type="button" className="btn btn-primary btn-lg btn-block btn btn-primary"> 支付宝直接付款</button>
              
             </NavLink>
 
             <div style={{margin:'20px'}}>付款方式二 支付宝扫码付款</div>
             <QRCode
      id="bill_qr_code_url"
      value={"https://hmb.zhongdaiqi.com/hopeapi/order/pay?orderCode="+this.state.orderCode+"&token="+this.state.token} //value参数为生成二维码的链接 我这里是由后端返回
      size={200} //二维码的宽高尺寸
      fgColor="#000000"  //二维码的颜色
      logo={"/alipaylogo.png"}
      logoWidth={50}
      logoHeight={50}
  />
 
          
 
             <div style={{margin:'20px'}}>付款方式三 联系平台客服付款</div>
           <Card body className="mt-3"><img src="haomabaoqrcode.png"  style={{width:"100%"}}></img></Card>
       
 
            <Card body className="mt-3"  style={{textAlign:"left"}}>
         
            </Card>
        </Container>
       
    </div>
  }
}

export default FlexProductPay;
