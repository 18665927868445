import React from "react";
import { Card } from 'react-bootstrap';


class QueryOrderFlow extends React.Component {
  constructor(props) {
    super(props);
  
  }

  render() {
    return this.props.orderFlow.map((li, index) => {
      //console.log(li)
     
      return (
        <Card className="p-2 bd-highlight mt-1" key={index}>
        <Card.Body>
          <Card.Text>
           时间:{li.time?li.time:'未知'}  状态:{li.unicomStatus}

           {li.shortUrl?"认证连接："+li.shortUrl:''}
          </Card.Text>
        </Card.Body>
      </Card>



      );
    });
  }
}

export default QueryOrderFlow;