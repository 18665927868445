import React from "react";
import { Card } from 'react-bootstrap';


class NumberList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.numbers.map((li, index) => {
      //console.log(li)
      const link = "https://hmb.zhongdaiqi.com/v2/web/index?p="+li.serialNumber+"&belongCity="+li.belongCity+"&belongCityName="+li.belongCityName+"&cityCode="+li.cityCode+"&goodsId="+li.goodsId+"&provinceCode="+li.provinceCode+"&serialNumber="+li.serialNumber+"&channel="+this.props.channelCode+"&u="+this.props.u;
      return (
        <Card className="d-inline-flex p-2 bd-highlight mt-1" key={li.serialNumber}>
        <Card.Body>
          <Card.Text>
          <a href={link} target='_blank'>{li.serialNumber}</a>
          </Card.Text>
        </Card.Body>
      </Card>



      );
    });
  }
}

export default NumberList;