import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form,  Button , Card ,Container,Modal,Spinner ,Breadcrumb,Row,Col } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';


class FlexProduct extends React.Component {
  constructor(props) {
    super(props);

    console.log(window);
    if(window.location.hostname!=="localhost"){
      //hao.zhongdaiqi.com ->  hmb.zhongdaiqi.com
      if(window.location.hostname==="hao.zhongdaiqi.com"){
          //确保是https
          if(window.location.protocol==="http:"){
            console.log("自动跳转到https 确保用户数据安全");  
            window.location.href=window.location.href.replace("http://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }else{
            window.location.href=window.location.href.replace("https://hao.zhongdaiqi.com","https://hmb.zhongdaiqi.com")
          }
      }

      //确保是https
      if(window.location.protocol==="http:"){
        console.log("自动跳转到https 确保用户数据安全");  
        window.location.href=window.location.href.replace("http://","https://")
      }
    }

    var u=props.match.params.u;
    if(!u){
      //先尝试从本地缓存读取
      try {
        //尝试读取本地追踪标
        var local_u = localStorage.getItem("u");//获取存储的元素
        if(local_u){
          console.log("本地缓存有追踪标，重置默认推广员");
          u = local_u;
        }else{
          u="zuime";
        }
      }
      catch(err) {
        console.log("读取追踪标出现了异常");
        u="zuime";//使用默认标
      }
    
    }else{
      //写入本地缓存
      try {
        //写入本地永久缓存
        localStorage.setItem("u",u);//写入本地 追踪标
      }
      catch(err) {
        console.log("写入本地追踪标出现了异常");
      }
    }

    this.state = {
      product:{

      },
      u:u,
      imageId1PreviewUrl:'',
      imageId1File:'',
      showId1:false,
      imageId0PreviewUrl:'',
      imageId0File:'',
      showId0:false,
      imageIdxPreviewUrl:'',
      imageIdxFile:'',
      showIdx:false,
      showBtn:true
    };

    console.log("能获取到的参数情况");
    console.log(props);
    this.handleSubmit = this.handleSubmit.bind(this);


    console.log("加载产品列表");
    console.log(props.match);

    console.log("参数");
    console.log(props);

 
    var that = this;
    axios.get( "hopeapi/product/"+props.match.params.id )
    .then(function (response) {
        console.log("request done");
        console.log(response);
        if(response.data.status){
          that.setState({
            product: response.data.data
          })

            let debugdata = new FormData();
            debugdata.append('msg',"代理："+that.state.u+";客户访问："+response.data.data.productTitle);
  
            axios.post("/lkjapi/msg.php",debugdata)
            .then(function () {
              
            
            });

          
        }else{
          alert("此产品已下架");
          that.props.history.push("/big?u="+u);
        }
        
    })
    .catch(function(error){
       console.log(error)
    })


}

handleImageId1Change(e) {
  console.log("wa you want to upload");
  e.preventDefault();
    
  var reader = new FileReader();
  var file = e.target.files[0];
  
  reader.onloadend = () => {
    console.log('文件名为—',file);
    console.log('文件结果为—',reader.result);
    this.setState({
      imageId1File:file,
      imageId1PreviewUrl: reader.result,
      showId1:true
    });
  }
  
  reader.readAsDataURL(file) 

}

handleImageId0Change(e) {
  console.log("wa you want to upload");
  e.preventDefault();
    
  var reader = new FileReader();
  var file = e.target.files[0];
  
  reader.onloadend = () => {
    console.log('文件名为—',file);
    console.log('文件结果为—',reader.result);
    this.setState({
        imageId0File:file,
        imageId0PreviewUrl: reader.result,
        showId0:true
    });
  }
  
  reader.readAsDataURL(file) 

}

handleImageIdxChange(e) {
  console.log("wa you want to upload");
  e.preventDefault();
    
  var reader = new FileReader();
  var file = e.target.files[0];
  
  reader.onloadend = () => {
    console.log('文件名为—',file);
    console.log('文件结果为—',reader.result);
    this.setState({
       imageIdxFile:file,
       imageIdxPreviewUrl: reader.result,
       showIdx:true
    });
  }
  
  reader.readAsDataURL(file) 

}

uploadImage(api_url,file,orderCode,imageToken,nextJob){
  const formData = new FormData();
  console.log("开始上传照片------");
  // formData.append('file',value);
  formData.append('file', file)
  formData.append('token',imageToken);
  formData.append('orderCode',orderCode);
  let config = {
    method: 'post',
    headers:{'Content-Type': 'multipart/form-data'}
  }
  axios.post(api_url,formData,config).then((res) => {
    console.log("图片上传结果");
    console.log(res);
    if (res.status != 200) {
      alert("照片上传失败，请重试或者联系客服提交！");
      return false;
     }
    if (res.status === 200 && !res.data.status) {
 
      alert("照片上传失败，请重试或者联系客服提交！");
      return false;
    }

    //继续执行
    nextJob();

  }).catch((error) => {
    alert("照片上传失败，请重试或者联系客服提交！");
  })

}

uploadImageAll(uploadImageConfig,successJob,errorJob){
  const {api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken} = uploadImageConfig;
  const formData = new FormData();
  console.log("开始上传照片------");
  // formData.append('file',value);
  formData.append('id1', imageId1File);
  formData.append('id0', imageId0File);
  formData.append('idx', imageIdxFile);
  formData.append('token',imageToken);
  formData.append('orderCode',orderCode);
  let config = {
    method: 'post',
    headers:{'Content-Type': 'multipart/form-data'}
  }
  axios.post(api_url,formData,config).then((res) => {
    console.log("图片上传结果");
    console.log(res);
    if (res.status != 200) {
  
      errorJob();
      return false;
     }
    if (res.status === 200 && !res.data.status) {
 
    
      errorJob();
      return false;
    }

    //继续执行
    successJob();

  }).catch((error) => {
    errorJob();
 
  })

}

handleSubmit = (event)=>{
  event.preventDefault() //阻止表单提交
  const {number,idname,idnum,address} = this
  const {productPrice,productVCode,productTitle,productMode} = this.state.product;
  var that = this;
  console.log("可以提交数据了");
 
  if(number.value==="" || !/\d{11}/gi.test(number.value)){
        alert("请输入手机号码！");
        return;
    }
  

    if(idname.value==="" ){
      alert("请输入姓名！");
      return;
  }
  
  if(idnum.value==="" ){
    alert("请输入证件号码！");
    return;
}
  
if(address.value==="" ){
  alert("请输入收货地址！");
  return;
}

//如果当前业务为需要传照片的，则检查照片是否上传
if(productMode==="image"){
    console.log(this.state.imageId1File);
    console.log(this.state.imageId0File);
    console.log(this.state.imageIdxFile);

    if(!this.state.imageId1File || !this.state.imageId0File || !this.state.imageIdxFile){
      alert("请上传照片！");
      return;
    }

}

    let orderdata = new FormData();
    orderdata.append('sku',productTitle+".来源H5");
    orderdata.append('id_name',idname.value);
    orderdata.append('id_num',idnum.value);
    orderdata.append('mobile',number.value);
    orderdata.append('address',address.value);
    orderdata.append('sku_code',productVCode);
    orderdata.append('u',this.state.u);
 
  that.setState({
      showBtn:false
  });
  axios.post("/lkjapi/api1.1.php",orderdata)
  .then(function (response) {
    console.log(response);

    if(response.status!==200){
      alert("下单失败，稍后重试");
      that.setState({
        showBtn:true
       });
      return;
    }
  
    if(!response.data.status || response.data.status==="false"){
      alert(response.data.message);
      that.setState({
        showBtn:true
       });
      return;
    }
    number.value="";
    idname.value="";
    idnum.value="";
    number.value="";
    address.value="";
    //如产品需要提交照片，则开始上传照片
    if(productMode==='image'){
        //"/hopeapi/biz/upload/id1"
        //  api_url,imageId1File,imageId0File,imageIdxFile,orderCode,imageToken
        var uploadImageConfig={
          api_url: "/hopeapi/biz/upload/ids",
          imageId1File: that.state.imageId1File,
          imageId0File: that.state.imageId0File,
          imageIdxFile: that.state.imageIdxFile,
          orderCode: response.data.data.orderCode,
          imageToken: response.data.data.imageToken
        }
        that.uploadImageAll(uploadImageConfig,function(){
          if(productPrice>0){
            alert("您下单的是付费产品，请联系页面底部平台客服，支付费用。");
            that.setState({
              showBtn:true,
              showId1:false,
              showId0:false,
              showIdx:false
             });
          }
        },function(){

          alert("照片上传失败，请重试或者联系客服提交！");
          that.setState({
            showBtn:true
           });
         

        });

    }else{
     
      if(productPrice>0){
        //alert("您下单的是付费产品，请联系页面底部平台客服，支付费用。");
        //跳到付款提示页面，拍出付款码/付款按扭

        that.setState({
          showBtn:true
         });

         that.props.history.push("/pay/"+response.data.data.orderCode+"/"+response.data.data.imageToken);
      }else{
        alert(response.data.message);
      }
    }
   
  });
}




  render() {
 
   return  <div className="App">


          <Container>
        
          <Breadcrumb>
            <Breadcrumb.Item href={"#/cha?u="+this.state.u}>
               查单
            </Breadcrumb.Item>

            <Breadcrumb.Item active>三大运营商优惠套餐</Breadcrumb.Item>
          </Breadcrumb>

 
         <div style={{fontSize:"12px"}}>
         {this.state.product.productTitle}
         </div>


         <img src={this.state.product.productCoverUrl} style={{width:"100%"}}/>
       
          <Form style={{marginTop: '1em'}}>

          <Form.Group controlId="formBasicIdName" >
            <Form.Control type="text" placeholder="输入姓名" ref={c => this.idname = c}  name="idname" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>


          <Form.Group controlId="formBasicIdNum" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入身份证号" ref={c => this.idnum = c}  name="idnum" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>



          <Form.Group controlId="formBasicNumber" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入联系号码" ref={c => this.number = c}  name="number" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicAddress" style={{marginTop: '0.5em'}}>
            <Form.Control type="text" placeholder="输入收货地址(省市区以及详细地址)" ref={c => this.address = c}  name="address" />
            <Form.Text className="text-muted">
            </Form.Text>
          </Form.Group>
       
         <div style={{display:this.state.product.productMode==='image'?"block":"none"}}>
           <div className="hmb-product-tip">根据有关部门要求，必须上传真实身份证照片。<span style={{color: "red"}}>未上传照片，订单无效。</span></div>

<div className="clearfix"></div>


<Container>
  <Row>
    <Col>
          <div className="hmb-van-uploader" style={{display: this.state.showId1?'none':'block'}}>
            <input type="file" accept="image/*" className="hmb-van-uploader__input"  onChange={(e)=>this.handleImageId1Change(e)} ></input>
          </div> 
          <img src={this.state.imageId1PreviewUrl}  className="hmb-preview-pic" style={{display: this.state.showId1?'block':'none'}}></img>
    </Col>
    <Col ><img src='face.png' className='hmb-product-idcard-image' /></Col>
  </Row>
  <Row>
    <Col className='hmb-uploader-tip'>证件四角边缘空隙2厘米以上，手机横拍，照片清晰不模糊，无水印，无反光，无PS</Col>
  </Row>
  <Row>
    <Col>
            <div className="hmb-van-uploader"  style={{display: this.state.showId0?'none':'block'}}>
            <input type="file" accept="image/*" className="hmb-van-uploader__input"  onChange={(e)=>this.handleImageId0Change(e)}></input>
          </div> 
          <img  src={this.state.imageId0PreviewUrl}   className="hmb-preview-pic" style={{display: this.state.showId0?'block':'none'}}></img>
    </Col>
    <Col><img src='back.png' className='hmb-product-idcard-image' /></Col>
  </Row>
  <Row>
    <Col className='hmb-uploader-tip'>证件四角边缘空隙2厘米以上，手机横拍，照片清晰不模糊，无水印，无反光，无PS</Col>
  </Row>
  <Row>
    <Col>
          <div className="hmb-van-uploader"  style={{display: this.state.showIdx?'none':'block'}}>
            <input type="file" accept="image/*" className="hmb-van-uploader__input"  onChange={(e)=>this.handleImageIdxChange(e)}></input>
          </div> 
          <img  src={this.state.imageIdxPreviewUrl}   className="hmb-preview-pic"  style={{display: this.state.showIdx?'block':'none'}}></img>
    </Col>
    <Col><img src='head.png' className='hmb-product-idcard-image' /></Col>
  </Row>
  <Row>
    <Col className='hmb-uploader-tip'>无需手持身份证，胸部以上，露出双肩，人像面部比例不低于1/3，无水印，无反光，无PS</Col>
  </Row>
</Container>
         </div>

 

         <p className="text-center">
          <Button variant="primary"  style={{ marginTop: '0.5em', display: this.state.showBtn?'block':'none' }}  type="button"  onClick={this.handleSubmit}  className="btn btn-primary btn-lg btn-block">
          {this.state.product.productPrice}元包邮
          </Button>

          <Button variant="default"  style={{ marginTop: '0.5em', display: !this.state.showBtn?'block':'none' }}  type="button"    className="btn btn-primary btn-lg btn-block">
             正在提交，请稍后
          </Button>

          <Card body className="mt-3"  style={{textAlign:"center",fontSize:"12px",fontWeight: "bold",color:"red", display: this.state.product.productStatus==='候车'?'block':'none' }}>
              下单候车，延期办理,耐心等待。着急请看<a href='/#/big'>29元103G产品</a>
          </Card>
            
          </p>
            <img src={this.state.product.productDetailUrl} style={{width:"100%"}}/>

           <Card body className="mt-3"> 平台客服</Card>
           <Card body className="mt-3"><img src="haomabaoqrcode.png"  style={{width:"100%"}}></img></Card>
        </Form>
 
            <Card body className="mt-3"  style={{textAlign:"left"}}>
         
            </Card>
        </Container>
       
    </div>
  }
}

export default FlexProduct;
