import React from "react";
import Product from "./Product"


class ProductList extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            productList:[
                {goodsId:'981701127085',productName:'阿里钉钉商务银卡'},
                {goodsId:'512205138781',productName:'广东流量王超享版39元（腾讯版）'},
                {goodsId:'512205138782',productName:'广东流量王超享版39元（阿里版）'},
                {goodsId:'512205138783',productName:'广东流量王超享版39元（头条版）'},
                {goodsId:'982012230462',productName:'宝卡PRO'},
                {goodsId:'982101061332',productName:'小龙卡'},
                {goodsId:'982101061334',productName:'大龙卡'},
                {goodsId:'982101061335',productName:'天龙卡'},
                {goodsId:'982112279603',productName:'大王卡2.0'},
                {goodsId:'982112249498',productName:'阿里宝卡轻享版'},
                {goodsId:'982101182332',productName:'菜鸟宝卡mini'},
                {goodsId:'982012240498',productName:'菜鸟大宝'},
                {goodsId:'982012240497',productName:'菜鸟小宝'},
                {goodsId:'982112148754',productName:'大驿Pro'},
                {goodsId:'982007162554',productName:'小驿卡'},
                {goodsId:'982112249499',productName:'微卡pro'},
                {goodsId:'981808163933',productName:'大乐卡'},
                {goodsId:'982112249501',productName:'兴农卡'}
              
            ]
        }
    
    
    };



    render() {
      let {onClick} = this.props;
      return this.state.productList.map((product, index) => {
                    return   <Product key={index}  onClick={onClick} goodsId={product.goodsId}  productName={product.productName} />
                        
                });

    }
  }
  
  export default ProductList;